import React, { useReducer, Dispatch } from "react";
import reducer from "../reducer/playback";

export interface PlaybackState {
  position: number;
}

export type PlaybackAction = {
  type: "UPDATE_POSITION";
  payload: { time: number };
};

export const initialState: PlaybackState = {
  position: 0
};

const PlaybackStore = React.createContext<{
  state: PlaybackState;
  dispatch: Dispatch<PlaybackAction>;
}>({
  state: initialState,
  dispatch: () => {}
});

const PlaybackProvider = (props: { children: React.ReactChild }) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlaybackStore.Provider value={{ state, dispatch }}>
      {children}
    </PlaybackStore.Provider>
  );
};

export { PlaybackStore, PlaybackProvider };
