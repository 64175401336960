import React, { useEffect } from "react";
import styled from "styled-components";
import { AppProvider } from "./store/app";
import { MyDropZone } from "./components/DropZone";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Route, Link, useLocation } from "react-router-dom";
import { FaQuestion } from "react-icons/fa";
import ReactGA from "react-ga";
import config from "./config";
import "ress/dist/ress.min.css";

const Wrapper = styled.div`
  color: ${config.color};
  max-width: 800px;
  height: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    zoom: 1.5;
  }

  h1 a {
    color: blue;
    text-decoration: none;
  }

  button {
    appearance: none;
    color: ${config.color};
    font-weight: bold;
    height: 100%;
    background-color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    user-select: none;
  }

  select {
    background: transparent;
    border: none;
    color: ${config.color};
    font-weight: bold;
    appearance: menulist;
  }
`;

const Header = styled.div`
  margin: 1em 0 2em;

  > div {
    display: flex;
    justify-content: space-between;
  }

  summary {
    font-size: 0.9rem;
    list-style: none;
  }

  .about {
    line-height: 2.5em;

    .short {
      display: none;
    }

    .long {
      font-size: 16px;
    }

    @media (max-width: 800px) {
      .short {
        display: inline;
      }

      .long {
        display: none;
      }
    }
  }

  .config svg {
    vertical-align: bottom;
  }
`;

const Main = styled.div`
  position: relative;
`;

const App = () => {
  const location = useLocation();
  const webAudioEnabled =
    typeof AudioContext !== "undefined" ||
    typeof webkitAudioContext !== "undefined";

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      ReactGA.initialize(config.trackingID, {
        debug: process.env.NODE_ENV !== "production",
      });

      if (process.env.NODE_ENV !== "production") {
        ReactGA.set({ sendHitTask: null });
      }
    }
    ReactGA.pageview(location.pathname);
  });

  return (
    <Wrapper>
      <AppProvider>
        <>
          <Header>
            <div>
              <h1>
                <Link to="/">Unmixer</Link>
              </h1>
              <div className="about">
                <Link to="/about">
                  <span className="long">What is Unmixer?</span>
                  <span className="short">
                    <FaQuestion />
                  </span>
                </Link>
              </div>
            </div>
            <summary>An interface for extracting and remixing loops</summary>
          </Header>
          <Main>
            {webAudioEnabled && (
              <MyDropZone quickstart={location.pathname === "/quickstart"} />
            )}
            {!webAudioEnabled && (
              <p>
                Your browser does not support Web Audio API. Please use a
                browser that supports Web Audio API.
              </p>
            )}
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
          </Main>
        </>
      </AppProvider>
    </Wrapper>
  );
};

export default App;
