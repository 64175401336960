import { useEffect } from "react";
import ReactGA from "react-ga";

export const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return null;
};
