import { PlaybackState, PlaybackAction } from "../store/playback";

const updatePosition = (state: PlaybackState, position: number) => ({
  ...state,
  position
});

const reducer = (
  state: PlaybackState,
  action: PlaybackAction
): PlaybackState => {
  switch (action.type) {
    case "UPDATE_POSITION":
      return updatePosition(state, action.payload.time);
    default:
      return state;
  }
};

export default reducer;
