import React, { useContext, useEffect } from "react";
import { FileStore } from "../store/file";
import { PlaybackStore } from "../store/playback";

export const Metronome = (props: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const {
    state: { stopwatch }
  } = useContext(FileStore);
  const playbackContext = useContext(PlaybackStore);

  useEffect(() => {
    stopwatch.ontimeupdate = time =>
      playbackContext.dispatch({
        type: "UPDATE_POSITION",
        payload: {
          time
        }
      });

    return () => {
      stopwatch.ontimeupdate = undefined;
    };
  });

  return <React.Fragment>{props.children}</React.Fragment>;
};
