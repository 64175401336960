import { Data } from "./Mixer";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

type Track = {
  url: string;
  name: string;
  link: string;
  activeIndexes: number[];
};

type UnmixData = Record<string, string>;

interface UnmixResponse {
  id: string;
  data: UnmixData;
  jobId: string;
}

interface UnmixStatusResponse {
  status: "DOING" | "DONE" | "CANCELLED";
}

export const loadPreComputedSongs = async (): Promise<Data[]> => {
  const files: Data[] = [];
  const tracks: Track[] = [
    {
      url: "/jw.json",
      name: '"Wow!" by J.L.T.',
      link: "https://www.jamendo.com/track/1011781/wow",
      activeIndexes: [0, 1],
    },
    {
      url: "/ab.json",
      name: '"Bang You Out" by Amy McFollow',
      link: "https://www.jamendo.com/track/1357869/bang-you-out",
      activeIndexes: [1],
    },
  ];

  return Promise.all(
    tracks.map(
      (track) =>
        new Promise<[AxiosResponse<UnmixData>, Track]>((resolve) => {
          return axios
            .get<UnmixData>(track.url)
            .then((res) => resolve([res, track]));
        })
    )
  ).then((values) => {
    values.forEach(([res, track]) => {
      const data: Data = {
        name: track.name,
        link: track.link,
        tracks: [],
        downloadSuffixText:
          '(loops licensed <a href="https://creativecommons.org/licenses/by-nc-sa/2.0/" target="_blank">CC-BY-NC-SA</a>)',
      };

      const n = Math.random().toString();
      let i = 0;
      for (const k in res.data) {
        data.tracks.push({
          id: k + n,
          base64Data: res.data[k],
          active: track.activeIndexes.indexOf(i) !== -1,
        });

        i++;
      }

      files.push(data);
    });

    return files;
  });
};

export interface LoadSongOption {
  timeoutSec: number;
  waitSec: number;
}

export const loadSong = async (
  numLoops: number,
  file: File,
  signal: AbortSignal,
  option: LoadSongOption = {
    timeoutSec: 10 * 60,
    waitSec: 5,
  }
): Promise<Data> => {
  const url = "https://g6gaqe8muy.ap-northeast-1.awsapprunner.com/api/v1/unmix";
  const formData = new FormData();
  const axiosOption: AxiosRequestConfig = { signal };

  formData.append("numLoops", numLoops.toString());
  formData.append("file", file);

  const res = await axios.post<UnmixResponse>(url, formData, axiosOption);
  const id = res.data.id;
  const jobId = res.data.jobId;
  let cancelled = false;

  signal.addEventListener("abort", () => {
    // TODO: use cancellation API

    cancelled = true;
  });

  let unmixData = res.data.data;
  const start = new Date().getTime();
  while (!unmixData) {
    const time = new Date().getTime();

    if ((time - start) / 1000 > option.timeoutSec) {
      throw new Error("timeout");
    }

    if (cancelled) {
      throw new Error("cancelled");
    }

    const res = await axios.get<UnmixStatusResponse>(
      url + "/" + id + "/jobs/" + jobId,
      axiosOption
    );

    if (res.data.status === "DONE") {
      const res = await axios.post<UnmixResponse>(url, formData, axiosOption);

      unmixData = res.data.data;

      break;
    } else if (res.data.status === "CANCELLED") {
      throw new Error("cancelled");
    }

    await wait(option.waitSec * 1000);
  }

  const data: Data = {
    name: file.name,
    tracks: [],
  };
  const n = Math.random().toString();

  for (const k in unmixData) {
    data.tracks.push({
      id: k + n,
      base64Data: unmixData[k],
      active: false,
    });
  }

  return data;
};

export const errorMessage = (e: Error): string => {
  const defaultMessage = e.message;

  if (axios.isAxiosError(e)) {
    if (!e.response) {
      return defaultMessage;
    }

    switch (e.response.status) {
      case 504:
        return "looks like the server is taking too long to respond, please try again later.";
      case 500:
        return "intenal server error";
      default:
        return defaultMessage;
    }
  }

  return e.message;
};

const wait = (msec: number) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
