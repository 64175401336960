export class AudioBufferStopwatch {
  // about implementation, see https://github.com/WebAudio/web-audio-api/issues/296#issuecomment-459514360

  position: number;
  timingNode: AudioBufferSourceNode | null;
  timingBuffer: AudioBuffer;
  scriptNode: ScriptProcessorNode;
  ontimeupdate?: (t: number) => void;

  constructor(private audioContext: AudioContext, frameCount: number) {
    this.scriptNode = audioContext.createScriptProcessor(4096, 1, 1);
    this.position = 0;
    this.timingNode = null;
    this.timingBuffer = audioContext.createBuffer(
      1,
      frameCount,
      audioContext.sampleRate
    );
    const data = this.timingBuffer.getChannelData(0);
    const duration = frameCount / audioContext.sampleRate;

    for (let i = 0; i < frameCount; i++) {
      data[i] = 2 * (i / frameCount) - 1;
    }

    this.scriptNode.onaudioprocess = audioProcessingEvent => {
      const input = audioProcessingEvent.inputBuffer;
      const inputData = input.getChannelData(0);

      this.position = duration * ((inputData[inputData.length - 1] + 1) / 2);

      if (this.ontimeupdate) {
        this.ontimeupdate(this.position);
      }
    };
  }

  start(start: number, position: number, playbackRate: number) {
    const { audioContext, timingBuffer, scriptNode } = this;

    this.timingNode = audioContext.createBufferSource();

    const timingNode = this.timingNode;

    timingNode.buffer = timingBuffer;
    timingNode.connect(this.scriptNode);
    timingNode.loop = true;
    timingNode.playbackRate.value = playbackRate;
    timingNode.start(start, position);
    scriptNode.connect(audioContext.destination);
  }

  stop() {
    const { timingNode, scriptNode } = this;

    this.position = 0;

    if (this.ontimeupdate) {
      this.ontimeupdate(this.position);
    }

    if (timingNode) {
      timingNode.stop();
      timingNode.disconnect();
      scriptNode.disconnect();
    }
  }

  setPlaybackRate(value: number, time: number) {
    const { timingNode } = this;

    if (timingNode) {
      timingNode.playbackRate.setValueAtTime(value, time);
    }
  }

  get currentPosition(): number {
    return this.position;
  }
}

export class NullStopWatch {
  start(_: number, __: number, ___: number) {}
  stop() {}
  setPlaybackRate(_: number, __: number) {}
  get currentPosition(): number {
    return 0;
  }
}
