export const playbackRateToBPM = (
  playbackRate: number,
  duration: number,
  numberOfBeats: number
): number => Math.round(60 / (duration / (playbackRate * numberOfBeats)));

export const bpmToPlaybackRate = (
  bpm: number,
  duration: number,
  numberOfBeats: number
): number => duration / ((60 / bpm) * numberOfBeats);

// base64 string -> array buffer
export const dataToBuffer = function(base64Data: string) {
  const byteString = atob(base64Data);
  const len = byteString.length;
  const buffer = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    buffer[i] = byteString.charCodeAt(i);
  }
  return buffer.buffer;
};
