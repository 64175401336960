import { FileState, FileAction, MixState } from "../store/file";
import config from "../config";
import { bpmToPlaybackRate } from "../utils";
import { AudioBufferStopwatch } from "../stopwatch";

const mix = (
  mixStates: Map<string, MixState>,
  gainNodes: Map<string, GainNode>
) => {
  let hasSolo = false;

  // check for the `solo` track
  mixStates.forEach((state) => {
    if (state.solo) {
      hasSolo = true;
    }
  });

  // +-----------+------+-----------------------+
  // | solo\mute | true |         false         |
  // +-----------+------+-----------------------+
  // | true      |  0.0 | 1.0                   |
  // | false     |  0.0 | has_solo? ? 0.0 : 1.0 |
  // +-----------+------+-----------------------+
  mixStates.forEach((state, id) => {
    const gain = gainNodes.get(id);

    if (gain) {
      if (hasSolo) {
        gain.gain.value = state.solo && !state.muted ? state.gain : 0.0;
      } else {
        gain.gain.value = !state.muted ? state.gain : 0.0;
      }
    }
  });
};

const setIDs = (state: FileState, ids: string[]) => {
  return {
    ...state,
    ids,
  };
};

const play = (
  state: FileState,
  audioContext: AudioContext,
  position: number,
  start: number
): FileState => {
  const { gainNodes, playbackRate, bufferSourceNodes, buffers, stopwatch } =
    state;

  buffers.forEach((buffer, id) => {
    if (buffer) {
      const node = audioContext.createBufferSource();
      const gain = gainNodes.get(id);

      if (gain) {
        node.buffer = buffer;
        node.loop = true;
        node.playbackRate.value = playbackRate;
        node.connect(gain);
        node.start(start, position);

        bufferSourceNodes.set(id, node);
      }
    }
  });

  stopwatch.start(start, position, playbackRate);

  return {
    ...state,
  };
};

const playOneshot = (
  state: FileState,
  audioContext: AudioContext,
  velocity: number,
  trackIndex: number
): FileState => {
  const { playbackRate, buffers, ids, oneshotNodes, oneshotCounts } = state;
  const id = ids[trackIndex];

  if (typeof id !== "undefined") {
    const buffer = buffers.get(id);

    if (buffer) {
      const node = audioContext.createBufferSource();
      const gain = audioContext.createGain();
      const oneshotNode = oneshotNodes.get(id);
      const oneshotCount = oneshotCounts.get(id);

      if (oneshotNode) {
        oneshotNode.disconnect();
      }

      gain.gain.value = velocity / 128;
      node.buffer = buffer;
      node.connect(gain);
      node.playbackRate.value = playbackRate;
      gain.connect(audioContext.destination);
      node.start(0);

      oneshotNodes.set(id, gain);
      oneshotCounts.set(id, (oneshotCount || 0) + 1);
    }

    return {
      ...state,
    };
  } else {
    return state;
  }
};

const stopOneshot = (state: FileState, trackIndex: number): FileState => {
  const { ids, oneshotNodes } = state;
  const id = ids[trackIndex];

  if (typeof id !== "undefined") {
    const oneshotNode = oneshotNodes.get(id);

    if (oneshotNode) {
      oneshotNode.disconnect();
      oneshotNodes.delete(id);
    }

    return {
      ...state,
    };
  } else {
    return state;
  }
};

const stop = (state: FileState): FileState => {
  const { bufferSourceNodes, stopwatch } = state;

  bufferSourceNodes.forEach((node, id) => {
    node.stop();
    node.disconnect();
    bufferSourceNodes.delete(id);
  });

  stopwatch.stop();

  return {
    ...state,
  };
};

const loadAudio = (
  state: FileState,
  id: string,
  buffer: AudioBuffer,
  audioContext: AudioContext,
  bpm: number,
  active: boolean
) => {
  const { buffers, mixStates, gainNodes } = state;

  const gain = audioContext.createGain();

  gain.connect(audioContext.destination);

  buffers.set(id, buffer);
  mixStates.set(id, { muted: !active, solo: false, gain: 1.0 });
  gainNodes.set(id, gain);

  mix(mixStates, gainNodes);

  const duration = buffer.duration;

  // set BPM and truncate decimal places
  const playbackRate = bpmToPlaybackRate(
    bpm | 0,
    duration,
    config.numberOfBeats
  );

  return {
    ...state,
    duration,
    playbackRate,
    stopwatch: new AudioBufferStopwatch(audioContext, buffer.length),
  };
};

const loadAudioError = (state: FileState, id: string, error: string) => {
  const { loadErrors } = state;

  loadErrors.set(id, error);

  return {
    ...state,
  };
};

const setMixState = (
  state: FileState,
  id: string,
  fn: (mixState: MixState) => void
) => {
  const { mixStates, gainNodes } = state;
  const mixState = mixStates.get(id);

  if (mixState) {
    fn(mixState);
    mixStates.set(id, {
      gain: mixState.gain,
      muted: mixState.muted,
      solo: mixState.solo,
    });
    mix(mixStates, gainNodes);

    return {
      ...state,
    };
  } else {
    return state;
  }
};

const setMute = (state: FileState, id: string, value: boolean) =>
  setMixState(state, id, (mixState) => {
    mixState.muted = value;
  });

const setSolo = (state: FileState, id: string, value: boolean) =>
  setMixState(state, id, (mixState) => {
    mixState.solo = value;
  });

const setGain = (state: FileState, id: string, value: number) =>
  setMixState(state, id, (mixState) => {
    mixState.gain = value;
  });

const setPlaybackRate = (state: FileState, bpm: number, start: number) => {
  const { bufferSourceNodes, stopwatch, duration } = state;
  const playbackRate = bpmToPlaybackRate(bpm, duration, config.numberOfBeats);

  bufferSourceNodes.forEach((node) => {
    node.playbackRate.setValueAtTime(playbackRate, start);
  });
  stopwatch.setPlaybackRate(playbackRate, start);

  return {
    ...state,
    playbackRate,
  };
};

const reducer = (state: FileState, action: FileAction): FileState => {
  switch (action.type) {
    case "SET_IDS":
      return setIDs(state, action.payload.ids);
    case "LOAD_AUDIO":
      return loadAudio(
        state,
        action.payload.id,
        action.payload.buffer,
        action.payload.audioContext,
        action.payload.bpm,
        action.payload.active
      );
    case "LOAD_AUDIO_ERROR":
      return loadAudioError(state, action.payload.id, action.payload.error);
    case "PLAY":
      return play(state, action.payload.audioContext, 0, action.payload.start);
    case "PLAY_ONESHOT":
      return playOneshot(
        state,
        action.payload.audioContext,
        action.payload.velocity,
        action.payload.trackIndex
      );
    case "STOP_ONESHOT":
      return stopOneshot(state, action.payload.trackIndex);
    case "STOP":
      return stop(state);
    case "SET_MUTE":
      return setMute(state, action.payload.id, action.payload.value);
    case "SET_SOLO":
      return setSolo(state, action.payload.id, action.payload.value);
    case "SET_GAIN":
      return setGain(state, action.payload.id, action.payload.value);
    case "SET_PLAYBACK_RATE":
      return setPlaybackRate(state, action.payload.bpm, action.payload.start);
    default:
      return state;
  }
};

export default reducer;
