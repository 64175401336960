import { Dispatch } from "react";
import ReactGA from "react-ga";
import { AppState, AppAction } from "../store/app";
import { FileAction } from "../store/file";

const play = (state: AppState): AppState => {
  const { fileDispatches, audioContext } = state;

  if (audioContext) {
    const start = audioContext.currentTime + 0.02;

    fileDispatches.forEach(dispatch => {
      dispatch({ type: "PLAY", payload: { audioContext, start } });
    });
  }

  return {
    ...state,
    playbackState: "playing",
    playbackStartTime: new Date().getTime()
  };
};

const stop = (state: AppState): AppState => {
  const { fileDispatches, playbackStartTime, playbackState } = state;

  if (playbackState === "playing") {
    ReactGA.timing({
      category: "Playback",
      variable: "playback time",
      value: new Date().getTime() - playbackStartTime
    });
  }

  fileDispatches.forEach(dispatch => {
    dispatch({ type: "STOP" });
  });

  return {
    ...state,
    playbackState: "paused"
  };
};

const setBPM = (state: AppState, bpm: number) => {
  const { fileDispatches, audioContext } = state;

  if (audioContext) {
    const start = audioContext.currentTime + 0.02;

    fileDispatches.forEach(dispatch => {
      dispatch({
        type: "SET_PLAYBACK_RATE",
        payload: {
          bpm,
          start
        }
      });
    });
  }

  return {
    ...state,
    bpm
  };
};

const setDispatch = (
  state: AppState,
  id: number,
  dispatch: Dispatch<FileAction>,
  trackIDs: string[]
) => {
  state.fileDispatches.set(id, dispatch);

  dispatch({
    type: "SET_IDS",
    payload: {
      ids: trackIDs
    }
  });

  return {
    ...stop(state)
  };
};

const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "PLAY":
      return play(state);
    case "STOP":
      return stop(state);
    case "SET_BPM":
      return setBPM(state, action.payload.bpm);
    case "SET_DISPATCH":
      return setDispatch(
        state,
        action.payload.id,
        action.payload.dispatch,
        action.payload.trackIDs
      );
    default:
      return state;
  }
};

export default reducer;
