import React, { SyntheticEvent } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Mixer, Data } from "./Mixer";
import { FileProvider } from "../store/file";
import { FaExclamation } from "react-icons/fa";
import styled from "styled-components";
import Loading from "react-loading";
import ReactGA from "react-ga";
import config from "../config";
import { loadPreComputedSongs, errorMessage, loadSong } from "./utils";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .quickstart {
    a {
      display: block;
      padding: 0.5em;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      background: ${config.color};

      color: #fff;
      text-decoration: none;
    }
  }
`;

const DropArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .dropzone {
    flex-shrink: 0;
    border: ${config.color} dashed 6px;
    margin: 1em 0 2em;
    padding: 2em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

  .dropzone--disabled {
    border: transparent solid 6px;
  }

  .dropzone--wide {
  }

  .loading {
    display: inline-block;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  button.quick-add {
    margin: 1em 0 0;
  }

  .maintenance {
    color: red;
  }

  @media (max-width: 800px) {
    .maintenance {
      font-size: 0.7rem;
    }
  }
`;

export class MyDropZone extends React.Component<
  { quickstart?: boolean },
  {
    files: Data[];
    processing: boolean;
    doQuickAdd: boolean;
    error: string | null;
    numLoops: number;
    processingFileName: string | null;
  }
> {
  state = {
    files: [],
    processing: false,
    doQuickAdd: false,
    error: null,
    numLoops: 10,
    processingFileName: null,
  };

  quickAdd = async () => {
    this.setState({
      processing: true,
      doQuickAdd: true,
      error: null,
      processingFileName: "pre-computed loops",
    });

    const files = await loadPreComputedSongs();

    this.setState({
      files: [...this.state.files, ...files],
      processing: false,
      doQuickAdd: true,
      error: null,
    });
  };

  onQuickAdd = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    this.quickAdd();
  };

  onClick = () => {
    ReactGA.event({
      category: "Navigation",
      action: "Click drop area",
    });
  };

  onDrop = async (acceptedFiles: File[]) => {
    const { numLoops } = this.state;
    const file = acceptedFiles[0];
    const processingStartTime = new Date().getTime();

    this.setState({
      error: null,
      processing: true,
      processingFileName: file.name,
    });

    ReactGA.event({
      category: "Navigation",
      action: "Drop a file",
    });

    try {
      const controller = new AbortController();
      const data = await loadSong(numLoops, file, controller.signal);

      ReactGA.timing({
        category: "Server",
        variable: "processing time",
        value: new Date().getTime() - processingStartTime,
      });

      this.setState({
        files: [...this.state.files, data],
        processing: false,
      });
    } catch (e) {
      if (e instanceof Error) {
        ReactGA.event({
          category: "Server",
          action: `Error: ${e.message}`,
        });

        this.setState({
          error: `Error: ${errorMessage(e)}`,
          processing: false,
        });
      }
    }
  };

  onSelectChange(event: SyntheticEvent<HTMLSelectElement, Event>) {
    this.setState({
      numLoops: parseInt(event.currentTarget.value),
    });
  }

  componentDidMount() {
    const { doQuickAdd } = this.state;
    const { quickstart } = this.props;

    if (quickstart && !doQuickAdd) {
      this.quickAdd();
    }
  }

  render() {
    const { files, processing, error, processingFileName } = this.state;
    const { quickstart } = this.props;
    const { isMaintenance, messageForMaintenance } = config;

    return (
      <Wrapper onClick={this.onClick}>
        {!quickstart && files.length === 0 && (
          <div className="quickstart">
            <a href="/quickstart">Quick Start</a>
          </div>
        )}

        {files.map((data, index) => (
          <FileProvider key={index}>
            <Mixer showControl={index === 0} id={index} data={data} />
          </FileProvider>
        ))}
        <Dropzone
          disabled={isMaintenance || processing}
          onDrop={this.onDrop}
          accept={{ "audio/*": [".mp3", ".wav"] }}
          multiple={false}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <DropArea>
                <div
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                    "dropzone--wide": files.length === 0,
                    "dropzone--disabled": processing,
                  })}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop a file here...</p>
                  ) : (
                    <>
                      {processing ? (
                        <div>
                          <Loading
                            type="bars"
                            color={config.color}
                            className="loading"
                          />
                          <p>Unmixing {processingFileName} ...</p>
                        </div>
                      ) : (
                        <div>
                          {error && (
                            <span>
                              <FaExclamation /> {error}
                              <br />
                            </span>
                          )}
                          {error && !this.state.doQuickAdd && (
                            <button
                              onClick={this.onQuickAdd}
                              className="quick-add"
                              title="Add some pre-computed loops"
                            >
                              Add pre-computed loops
                            </button>
                          )}

                          {!error && (
                            <div>
                              <h4>Unmix and isolate loops</h4>
                              {isMaintenance && (
                                <p className="maintenance">
                                  {messageForMaintenance}
                                </p>
                              )}
                              {!isMaintenance && (
                                <p>
                                  Try dropping an audio file here, or click to
                                  select an audio file to upload.
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </DropArea>
            );
          }}
        </Dropzone>
      </Wrapper>
    );
  }
}
