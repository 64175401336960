import React, { useContext, useCallback, useMemo, SyntheticEvent } from "react";
import { Track } from "./Track";
import { AppStore } from "../store/app";
import { FileStore } from "../store/file";
import { PlaybackStore, PlaybackProvider } from "../store/playback";
import { Metronome } from "./Metronome";
import styled from "styled-components";
import ReactGA from "react-ga";
import config from "../config";
import { playbackRateToBPM } from "../utils";
import { FaPlay, FaPause } from "react-icons/fa";
import { dataToBuffer } from "../utils";
import JsZip from "jszip";

export type Data = {
  name: string;
  link?: string;
  tracks: {
    id: string;
    base64Data: string;
    active: boolean;
  }[];
  downloadSuffixText?: string;
};

const Wrapper = styled.div`
  .tracks {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 1em;
    row-gap: 1em;
    margin: 1em 0 1em;
  }

  @media (max-width: 800px) {
    .tracks {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
  }

  .info .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .info .download {
    font-weight: bold;
  }

  .suffix {
    text-align: right;
    font-size: 0.7rem;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.5em;

  .play-button {
    font-size: 200%;
  }
`;
const Tempo = styled.div`
  align-self: flex-end;

  .bpm {
    text-align: right;
  }
`;

const PositionIndicatorWrapper = styled.div`
  margin: 0 0 1em;
  width: 100%;
  background: #ccc;

  div {
    height: 2px;
    background: ${config.color};
  }
`;

const PositionIndicator = () => {
  const {
    state: { position },
  } = useContext(PlaybackStore);
  const {
    state: { duration },
  } = useContext(FileStore);

  return (
    <Metronome>
      <div style={{ width: `${(position / duration) * 100}%` }}></div>
    </Metronome>
  );
};

interface ControlProps {
  bpm: number;
  togglePlaybackState: () => void;
  playbackState: "playing" | "paused";
  onSelectChange: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
}

const Control = React.memo((props: ControlProps) => (
  <React.Fragment>
    <button className="play-button" onClick={props.togglePlaybackState}>
      {props.playbackState === "playing" ? <FaPause /> : <FaPlay />}
    </button>
    <Tempo>
      <div className="bpm">
        BPM:
        <select value={props.bpm} onChange={props.onSelectChange}>
          {[...new Array(180)].map((_, i) => (
            <option value={i + 60} key={i}>
              {i + 60}
            </option>
          ))}
        </select>
      </div>
    </Tempo>
  </React.Fragment>
));

export const Mixer = (props: {
  id: number;
  data: Data;
  showControl: boolean;
}) => {
  const {
    state: { audioContext, playbackState, fileDispatches },
    dispatch: appDispatch,
  } = useContext(AppStore);
  const {
    state: { buffers, duration, playbackRate },
    dispatch: fileDispatch,
  } = useContext(FileStore);
  const {
    id,
    data: { tracks, name, link, downloadSuffixText },
  } = props;
  const showControl = props.showControl && buffers.size > 0;
  const ids = tracks.map(({ id }) => id);

  if (!audioContext) {
    return null;
  }

  if (!fileDispatches.get(id)) {
    appDispatch({
      type: "SET_DISPATCH",
      payload: {
        id,
        dispatch: fileDispatch,
        trackIDs: ids,
      },
    });
  }

  const bpm = useMemo(
    () => playbackRateToBPM(playbackRate, duration, config.numberOfBeats) | 0,
    [playbackRate, duration, config.numberOfBeats]
  );
  const togglePlaybackState = useCallback(() => {
    ReactGA.event({
      category: "Navigation",
      action: "Toggle playback",
    });

    appDispatch(
      playbackState === "playing" ? { type: "STOP" } : { type: "PLAY" }
    );
  }, [playbackState]);
  const changeBPM = (value: number) => {
    ReactGA.event({
      category: "Navigation",
      action: "Change BPM",
    });

    appDispatch({
      type: "SET_BPM",
      payload: {
        bpm: value,
      },
    });
  };

  const onSelectChange = useCallback(
    (event: SyntheticEvent<HTMLSelectElement, Event>) =>
      changeBPM(parseInt(event.currentTarget.value)),
    [duration]
  );

  const onClickDownloadLink = (
    e: SyntheticEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const zip = new JsZip();

    ReactGA.event({
      category: "Navigation",
      action: "Click download link",
    });

    props.data.tracks.forEach(({ base64Data }, index) => {
      zip.file(index + 1 + ".wav", dataToBuffer(base64Data));
    });

    zip.generateAsync({ type: "blob" }).then((blob) => {
      location.href = URL.createObjectURL(blob);
    });
  };

  return (
    <Wrapper>
      {showControl && (
        <>
          <ControlWrapper>
            <Control
              {...{
                togglePlaybackState,
                bpm,
                onSelectChange,
                playbackState,
              }}
            />
          </ControlWrapper>
          <PositionIndicatorWrapper>
            <PlaybackProvider>
              <PositionIndicator />
            </PlaybackProvider>
          </PositionIndicatorWrapper>
        </>
      )}
      <div className="info">
        <div className="name">
          {link && (
            <a href={link} target="_blank">
              {name}
            </a>
          )}
          {!link && name}
        </div>
        <div>
          <a
            className="download"
            href=""
            onClick={onClickDownloadLink}
            download
          >
            download
          </a>
        </div>
      </div>
      {downloadSuffixText && (
        <p
          className="suffix"
          dangerouslySetInnerHTML={{ __html: downloadSuffixText }}
        ></p>
      )}
      <div className="tracks">
        {props.data.tracks.map(({ id, base64Data, active }, index) => (
          <Track
            key={id}
            id={id}
            index={props.id * 10 + index}
            base64Data={base64Data}
            active={!!active}
          />
        ))}
      </div>
    </Wrapper>
  );
};
