import React, { Suspense, useContext, Dispatch, useMemo } from "react";
import { AppStore, AppAction } from "../store/app";
import { FileStore, FileAction, FileState } from "../store/file";
import styled from "styled-components";
import ReactGA from "react-ga";
import classNames from "classnames";
import config from "../config";
import { dataToBuffer, playbackRateToBPM } from "../utils";
import Transition from "react-transition-group/Transition";
import Waveform from "waveform-react";

const Wrapper = styled.div`
  @keyframes flash {
    from {
      border-width: 6px;
      opacity: 0;
    }

    to {
      border-width: 0;
      opacity: 1;
    }
  }

  background: #ddd;
  position: relative;

  div.control {
    display: flex;
    flex-grow: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  div.buttons {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background: ${config.color};
  }

  div.buttons button {
    margin-left: 0;
    margin-right: 0;
    background-color: #ddd;
    display: flex;
    flex-direction: column;
  }

  canvas {
    display: block;
  }

  div.buttons button > div {
    flex-grow: 1;
  }

  div.buttons button.active {
    color: #fff;
    background-color: ${config.color};
  }
`;

// https://qiita.com/usp/items/96f3cf9997ebb5b3dbb9
const Spacer = styled.div`
  width: 100%;
  padding-bottom: 100%;
`;

const loadAudio = (
  id: string,
  audioContext: AudioContext,
  base64Data: string,
  fileDispatch: Dispatch<FileAction>,
  appDispatch: Dispatch<AppAction>,
  bpm: number,
  active: boolean
): Promise<AudioBuffer> => {
  return new Promise((resolve, reject) => {
    try {
      const arrayBuffer = dataToBuffer(base64Data);

      audioContext.decodeAudioData(
        arrayBuffer,
        (audioBuffer) => {
          if (bpm === 0) {
            bpm = playbackRateToBPM(
              1.0,
              audioBuffer.duration,
              config.numberOfBeats
            );

            appDispatch({
              type: "SET_BPM",
              payload: {
                bpm,
              },
            });
          }

          fileDispatch({
            type: "LOAD_AUDIO",
            payload: {
              id: id,
              buffer: audioBuffer,
              audioContext,
              duration: audioBuffer.duration,
              bpm,
              active,
            },
          });

          // XXX: manually trigger `resize` event to display waveforms
          //
          // see:
          // - https://github.com/ruebel/waveform-react/blob/dd07fe894717e0464879d3f275f6e7388c599a0c/src/Wrapper.js#L45
          window.dispatchEvent(new Event("resize"));

          resolve(audioBuffer);
        },
        (error) => {
          const message = error.message;

          fileDispatch({
            type: "LOAD_AUDIO_ERROR",
            payload: { id: id, error: message },
          });
          reject(message);
        }
      );
    } catch (e) {
      if (e instanceof Error) {
        fileDispatch({
          type: "LOAD_AUDIO_ERROR",
          payload: { id: id, error: e.message },
        });
        reject(e);
      }
    }
  });
};

const resource = (() => {
  const promises = new Map<string, Promise<AudioBuffer>>();

  return {
    read: (
      id: string,
      audioContext: AudioContext,
      base64Data: string,
      state: FileState,
      fileDispatch: Dispatch<FileAction>,
      appDispatch: Dispatch<AppAction>,
      bpm: number,
      active: boolean
    ): AudioBuffer | null => {
      const { buffers, loadErrors } = state;
      const value = buffers.get(id);

      if (value) {
        return value;
      } else {
        const loadError = loadErrors.get(id);

        if (loadError) {
          return null;
        } else {
          let promise = promises.get(id);

          if (!promise) {
            promise = loadAudio(
              id,
              audioContext,
              base64Data,
              fileDispatch,
              appDispatch,
              bpm,
              active
            );
            promises.set(id, promise);
          }

          throw promise;
        }
      }
    },
  };
})();

const TrackLoader = (props: {
  id: string;
  base64Data: string;
  index: number;
  active: boolean;
}) => {
  const { state, dispatch: fileDispatch } = useContext(FileStore);
  const { oneshotCounts } = state;
  const oneshotCount = oneshotCounts.get(props.id);
  const { id, base64Data, index, active } = props;
  const {
    state: { audioContext, bpm },
    dispatch: appDispatch,
  } = useContext(AppStore);
  let oneshot = false;

  if (!audioContext) return null;

  const audioBuffer = resource.read(
    id,
    audioContext,
    base64Data,
    state,
    fileDispatch,
    appDispatch,
    bpm,
    active
  );

  useMemo(() => {
    oneshot = true;
  }, [oneshotCount]);

  const duration = 200;
  const defaultStyle = {
    border: "0 solid transparent",
    opacity: 1,
  };

  const transitionStyles = {
    entering: {
      animation: `${duration}ms cubic-bezier(0,1.03,.49,.85) 0s flash`,
    },
  };

  return (
    <Transition in={oneshot} timeout={duration}>
      {(state: "entering") => (
        <Wrapper
          style={{
            ...defaultStyle,
            ...(transitionStyles[state] || {}),
          }}
        >
          <TrackContainer buffer={audioBuffer} id={id} index={index} />
        </Wrapper>
      )}
    </Transition>
  );
};

const Controller = (props: {
  id: string;
  index: number;
  buffer: AudioBuffer;
}) => {
  const { id, buffer } = props;
  const {
    state: { mixStates },
    dispatch: fileDispatch,
  } = useContext(FileStore);
  const {
    state: { playbackState },
    dispatch: appDispatch,
  } = useContext(AppStore);
  const mixState = mixStates.get(id);

  if (!mixState) {
    return null;
  }

  return useMemo(
    () => (
      <div className="control">
        <div className="buttons">
          <button
            onClick={() => {
              ReactGA.event({
                category: "Navigation",
                action: "Click a loop",
              });

              if (playbackState === "paused" && mixState.muted) {
                appDispatch({
                  type: "PLAY",
                });
              }

              fileDispatch({
                type: "SET_MUTE",
                payload: { id, value: !mixState.muted },
              });
            }}
            className={classNames("mute-button", { active: !mixState.muted })}
          >
            <Waveform
              buffer={buffer}
              responsive={true}
              waveStyle={{
                color: mixState.muted ? "#999" : "#fff",
                animate: false,
                pointWidth: 1,
              }}
            />
          </button>
        </div>
      </div>
    ),
    [mixState, playbackState]
  );
};

const ErrorMessage = styled.p`
  padding: 1em;
`;

export const TrackContainer = (props: {
  id: string;
  buffer: AudioBuffer | null;
  index: number;
}) => {
  const { id, buffer, index } = props;

  return (
    <React.Fragment>
      <Spacer />
      {buffer && <Controller id={id} index={index} buffer={buffer} />}
      {!buffer && <ErrorMessage>Load Error</ErrorMessage>}
    </React.Fragment>
  );
};

export const Track = (props: {
  id: string;
  base64Data: string;
  index: number;
  active: boolean;
}) => {
  const { id, base64Data, index, active } = props;

  return (
    <Suspense
      fallback={
        <Wrapper>
          <React.Fragment>
            <Spacer />
            <p className="loading control" />
          </React.Fragment>
        </Wrapper>
      }
    >
      <TrackLoader
        key={id}
        id={id}
        index={index}
        active={active}
        base64Data={base64Data}
      />
    </Suspense>
  );
};
