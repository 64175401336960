import React, { useReducer, Dispatch } from "react";
import reducer from "../reducer/file";
import { NullStopWatch } from "../stopwatch";

// Stopwatch is a class for obtaining current playback position of AudioBufferSourceNode
interface Stopwatch {
  start(t: number, position: number, playbackState: number): void;
  stop(): void;
  setPlaybackRate(value: number, time: number): void;
  readonly currentPosition: number;
  ontimeupdate?: (t: number) => void;
}

export interface FileState {
  buffers: Map<string, AudioBuffer>;
  loadErrors: Map<string, string>;
  bufferSourceNodes: Map<string, AudioBufferSourceNode>;
  gainNodes: Map<string, GainNode>;
  mixStates: Map<string, MixState>;
  oneshotNodes: Map<string, GainNode>;
  oneshotCounts: Map<string, number>;
  duration: number;
  ids: string[];
  playbackRate: number;
  stopwatch: Stopwatch;
}

export interface MixState {
  muted: boolean;
  solo: boolean;
  gain: number;
}

export type FileAction =
  | {
      type: "SET_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "LOAD_AUDIO";
      payload: {
        id: string;
        buffer: AudioBuffer;
        audioContext: AudioContext;
        duration: number;
        bpm: number;
        active: boolean;
      };
    }
  | {
      type: "LOAD_AUDIO_ERROR";
      payload: {
        id: string;
        error: string;
      };
    }
  | {
      type: "PLAY";
      payload: {
        audioContext: AudioContext;
        start: number;
      };
    }
  | {
      type: "PLAY_ONESHOT";
      payload: {
        audioContext: AudioContext;
        velocity: number;
        trackIndex: number;
      };
    }
  | {
      type: "STOP_ONESHOT";
      payload: {
        trackIndex: number;
      };
    }
  | {
      type: "STOP";
    }
  | {
      type: "SET_MUTE";
      payload: {
        id: string;
        value: boolean;
      };
    }
  | {
      type: "SET_SOLO";
      payload: {
        id: string;
        value: boolean;
      };
    }
  | {
      type: "SET_GAIN";
      payload: {
        id: string;
        value: number;
      };
    }
  | {
      type: "SET_PLAYBACK_RATE";
      payload: {
        bpm: number;
        start: number;
      };
    };

const init = (_?: null): FileState => {
  return {
    buffers: new Map<string, AudioBuffer>(),
    bufferSourceNodes: new Map<string, AudioBufferSourceNode>(),
    gainNodes: new Map<string, GainNode>(),
    mixStates: new Map<string, MixState>(),
    oneshotNodes: new Map<string, GainNode>(),
    oneshotCounts: new Map<string, number>(),
    loadErrors: new Map<string, string>(),
    duration: 0,
    ids: [],
    playbackRate: 1.0,
    stopwatch: new NullStopWatch(),
  };
};
const FileStore = React.createContext<{
  state: FileState;
  dispatch: Dispatch<FileAction>;
}>({
  state: init(),
  dispatch: () => {},
});

const FileProvider = (props: { children: React.ReactChild }) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, null, init);

  return (
    <FileStore.Provider value={{ state, dispatch }}>
      {children}
    </FileStore.Provider>
  );
};

export { FileStore, FileProvider };
