import React, { useEffect } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  position: absolute;
  top: -2em;
  margin: 1em 1em 0;

  .content {
    border: 6px solid blue;
    background: #fff;
    padding: 1em;
    margin: 0 0 2em;
  }

  p,
  ol,
  ul {
    margin: 1em 0 1em;
  }

  ol,
  ul {
    margin-left: 2em;
  }

  .back {
    float: right;
    font-size: 16px;
  }
`;

export const About = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });
  return (
    <Wrapper>
      <div className="content">
        <Link to="/" className="back">
          Back
        </Link>
        <h3 id="what-is-unmixer">What is Unmixer?</h3>
        <p>
          Unmixer does two things: first, it lets you extract loops from any
          song; second, it lets you remix these loops in a simple interface.
        </p>
        <h3 id="how-do-i-use-it">How do I use it?</h3>
        <ol>
          <li>
            <strong>Upload a song</strong>: drag and drop an audio file (MP3,
            MP4) into the box with the dotted line
          </li>
          <li>
            <strong>Wait for results</strong>: processing a new song can take
            several minutes.
          </li>
          <li>
            <strong>Remix the loops</strong>: turn each sound on and off by
            clicking the box.
          </li>
          <li>
            <strong>Upload more songs</strong>: you can mash-up sounds from as
            many songs as you like.
          </li>
        </ol>
        <p>Extra features:</p>
        <ol>
          <li>
            The <strong>BPM indicator</strong> in the top right lets you choose
            the global tempo.
          </li>
          <li>
            The <strong>download button</strong> allows you to save a zipfile of
            all the loops, along with a map of where they occur in the piece.
          </li>
        </ol>
        <h3 id="quickstart-link">Quickstart link</h3>
        <p>
          It can take several minutes for Unmixer to process a track it has not
          seen before. To start right away with 2 pre-loaded songs, click here:
        </p>
        <p
          style={{
            textAlign: "center",
            padding: 5,
            width: "10em",
            margin: "0 auto",
            border: "2px solid #73AD21",
            borderColor: "blue",
            borderRadius: 5,
          }}
        >
          <a href="/quickstart">QUICKSTART WITH PRELOADED SONGS</a>
        </p>
        <p>
          Both of these songs are available on Jamendo and licensed{" "}
          <a
            href="https://www.jamendo.com/legal/creative-commons"
            target="_blank"
            rel="noopener noreferrer"
          >
            CC-BY-NC-SA
          </a>{" "}
          (attribution, non-commercial and share-alike). The songs are:
          <a
            href="https://www.jamendo.com/track/1011781/wow"
            target="_blank"
            rel="noopener noreferrer"
          >
            "Wow!" by J.L.T.
          </a>{" "}
          and{" "}
          <a
            href="https://www.jamendo.com/track/1357869/bang-you-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            "Bang You Out" by Amy McFollow
          </a>
          .
        </p>
        <p>
          Unmixer uses caching to provide faster results for music it has seen
          before. To download a set of redistributable songs posted on Jamendo
          that Unmixer already knows,{" "}
          <a href="https://github.com/jblsmith/CC-SA-Jamendo/archive/master.zip">
            click here
          </a>
          .
        </p>
        <h3 id="research-links">Research links</h3>
        <p>
          If you are interested in the details of the system design and the
          algorithm it uses, please check out the two papers we have published
          about it:
        </p>
        <ul>
          <li>
            <a
              href="http://jblsmith.github.io/projects/nonnegative-tensor-factorization/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ICASSP 2018 paper
            </a>
          </li>
          <li>
            <a
              href="http://jblsmith.github.io/projects/unmixer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ISMIR 2019 paper
            </a>
          </li>
        </ul>
        <h3 id="development-team">Development Team</h3>
        <p>
          Unmixer was developed by researchers in the Media Interaction Group at
          the National Institute of Advanced Industrial Science and Technology
          (AIST), Japan.
        </p>
        <ul>
          <li>
            <a
              href="http://jblsmith.github.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jordan B. L. Smith
            </a>{" "}
            [core algorithm &amp; web app]
          </li>
          <li>Yuta Kawasaki [web app &amp; environment setup]</li>
          <li>
            <a
              href="https://staff.aist.go.jp/m.goto/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Masataka Goto
            </a>{" "}
            [research supervision]
          </li>
        </ul>
        <h3 id="terms-of-use">Terms of use</h3>
        <p>
          You need to carefully read and agree the following Terms of Use before
          using this website.
        </p>
        <ul>
          <li>
            Terms of Use [{" "}
            <a target="_blank" href="/terms_of_use.pdf">
              PDF
            </a>{" "}
            ]
          </li>
          <li>
            See also: Policies [{" "}
            <a
              target="_blank"
              href="https://ongaaccel.jp/plla_procedure.pdf"
              rel="noopener noreferrer"
            >
              PDF
            </a>
            ,{" "}
            <a target="_blank" href="/privacy_policy.pdf">
              PDF
            </a>{" "}
            ]
          </li>
        </ul>
        <p>
          Any use of this website or any use of data provided by this website is
          at your own risk. AIST Media Interaction Group will not be liable for
          any loss or damage arising from the use of this website.
        </p>
        <h3 id="acknowledgement">Acknowledgement</h3>
        <p>
          This work was supported in part by JST ACCEL Grant Number JPMJAC1602,
          Japan.
        </p>

        <h3>Contact</h3>
        <p>unmixer-ml@aist.go.jp</p>
      </div>
    </Wrapper>
  );
};
