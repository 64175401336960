import React, { useReducer, useEffect, Dispatch } from "react";
import reducer from "../reducer/app";
import { FileAction } from "./file";

export interface AppState {
  playbackState: "paused" | "playing";
  audioContext?: AudioContext;
  bpm: number;
  fileDispatches: Map<number, Dispatch<FileAction>>;
  playbackStartTime: number;
}

export type AppAction =
  | {
      type: "PLAY";
    }
  | {
      type: "STOP";
    }
  | {
      type: "SET_BPM";
      payload: {
        bpm: number;
      };
    }
  | {
      type: "SET_DISPATCH";
      payload: {
        id: number;
        dispatch: Dispatch<FileAction>;
        trackIDs: string[];
      };
    };

export const initialState: AppState = {
  playbackState: "paused",
  playbackStartTime: 0,
  audioContext:
    typeof AudioContext !== "undefined"
      ? new AudioContext()
      : typeof webkitAudioContext !== "undefined"
      ? new webkitAudioContext()
      : undefined,
  bpm: 0,
  fileDispatches: new Map<number, Dispatch<FileAction>>()
};

const AppStore = React.createContext<{
  state: AppState;
  dispatch: Dispatch<AppAction>;
}>({
  state: initialState,
  dispatch: () => {}
});

const AppProvider = (props: { children: React.ReactChild }) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const onMidiMessage = (event: WebMidi.MIDIMessageEvent) => {
      const { audioContext } = state;

      if (audioContext) {
        const [command, noteNumber, velocity] = Array.prototype.slice.call(
          event.data
        );

        // note on/off?
        if (command === 0x90 || command === 0x80) {
          const noteOn = command === 0x90;
          const fileIndex = (noteNumber / 5) | 0;
          const trackIndex = noteNumber % 5;

          // XXX: consider file removal
          const fileDispatch = state.fileDispatches.get(fileIndex);

          if (fileDispatch) {
            fileDispatch(
              noteOn
                ? {
                    type: "PLAY_ONESHOT",
                    payload: {
                      audioContext,
                      velocity,
                      trackIndex
                    }
                  }
                : {
                    type: "STOP_ONESHOT",
                    payload: {
                      trackIndex
                    }
                  }
            );
          }
        }
      }
    };

    // TODO:
    // - handle errors
    // - cleanup
    (async () => {
      if (navigator.requestMIDIAccess) {
        const data = await navigator.requestMIDIAccess();

        data.inputs.forEach(input => {
          input.onmidimessage = onMidiMessage;
        });
      }
    })();
  }, []);

  return (
    <AppStore.Provider value={{ state, dispatch }}>
      {children}
    </AppStore.Provider>
  );
};

export { AppStore, AppProvider };
